.like-count {
  text-align: center;
  margin-left: 2px;
  font-size: 14px;
}

.like-icon {
  padding: 3px 9px !important;
}

.profilePageCard2 {
  border-radius: 15px;
}

.loadingSchool {
  color: #3caea3;
  font-size: 75px !important;
  animation: blinker 1s linear infinite;
}

.dialogHeadingCSS {
  font-weight: 700;
  font-size: 20px;
}

.dialogBodyCSS {
  font-weight: 600;
  font-size: 15px;
}

.dialogCloseCSS {
  color: #3caea3 !important;
}

.pp-lhs-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pp-lhs-img-wrapper img {
  height: 85%;
  width: 85%;
  object-fit: cover;
  border-radius: 15px;
}

.pp-history-back {
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 16px;
}

.pp-rhs-card {
  height: 100%;
  padding: 25px 25px 0 25px !important;
  overflow: auto;
}

.pp-user-info {
  display: flex;
  justify-content: space-between;
}

.pp-user-name-dept {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pp-user-name {
  font-size: 32px;
  font-weight: 500;
}

.pp-user-dept {
  font-size: 16px;
  color: #828282;
}

.pp-user-details-tabs svg {
  color: #582bee;
}

.pp-share-star-icons {
  display: flex;
  gap: 16px;
  margin-top: 15px;
  min-width: 70px;
}

.pp-share-icon svg {
  cursor: pointer;
}

.pp-share-star-icons svg {
  width: 24px;
  height: 26px;
  color: #582bee;
}

.pp-user-bday-container,
.pp-user-email-container {
  display: flex;
  align-items: center;
  color: #828282;
  gap: 8px;
}

.pp-user-bday-container svg,
.pp-user-email-container svg {
  width: 25px;
  height: 24px;
}

.pp-user-details-tabs .MuiAppBar-colorPrimary {
  color: #000;
  background-color: white;
  box-shadow: none;
  margin-top: 24px;
}

.pp-user-details-tabs .MuiButtonBase-root {
  text-transform: none;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.pp-user-details-tabs .MuiButtonBase-root:focus {
  outline: none;
}

.pp-user-details-tabs .MuiTabs-indicator {
  display: none;
}

.pp-user-details-tabs .Mui-selected {
  background: #efefef;
  border-radius: 50px;
  padding: 0 !important;
  font-weight: 500;
  transition: all 0.15s ease;
}

.pp-user-no-socials {
  color: #828282;
  padding: 25px 5px 0 5px;
  text-align: center;
}

.pp-user-about-quote {
  display: flex;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
  gap: 10px;
  color: #828282;
}

.pp-user-about {
  display: flex;
  gap: 25px;
}

.pp-scraps-none {
  padding: 25px 0;
  color: #919191;
  min-height: 40vh;
}

.pp-scraps-heading {
  font-size: 21px;
  font-weight: 500;
  margin-top: 40px;
}

.pp-scrap-container {
  display: flex;
  justify-content: flex-start;
  padding: 11px 0;
}

.pp-scrap-photo-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100px;
  gap: 10px;
  padding-top: 4px;
  margin-right: 5px;
}

.pp-scrap-photo .MuiAvatar-root {
  height: 45px;
  width: 45px;
}

.pp-scrap-name {
  font-size: 15px;
  font-weight: 600;
  width: 80px;
  text-align: center;
  cursor: pointer;
}

.pp-scrap-content {
  font-size: 15px;
  background: #efefef;
  color: #3a3a3a;
  padding: 12px 15px;
  border-radius: 17px;
  position: relative;
  word-wrap: anywhere;
}

.pp-scrap-time {
  color: #828282;
}

.pp-scrap-content-container {
  position: relative;
  width: 100%;
}

.pp-scrap-likes-time-container {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 15px;
  padding-top: 5px;
  margin-left: -10px;
  color: #582bee;
}

.pp-scrap-likes-time-container svg {
  height: 21px;
  width: 21px;
  color: #582bee;
}

.pp-user-socials {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  color: #828282;
}

.pp-user-social {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.pp-user-socials .pp-user-email-container svg {
  width: 28px;
  height: 24px;
}

.pp-new-scrap {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background: white;
  padding-bottom: 25px;
}

.pp-new-scrap .MuiFormControl-root {
  width: 100%;
  background: #c4c4c43d;
}

.pp-new-scrap .MuiFilledInput-input {
  padding: 0 5px;
}

.pp-new-scrap .MuiFilledInput-multiline {
  padding: 15px;
}

.pp-new-scrap .MuiFilledInput-root,
.pp-new-scrap .MuiFormControl-root {
  border-radius: 15px;
}

.pp-new-scrap .MuiFilledInput-underline::before {
  border-bottom: none !important;
  display: none;
}

.pp-new-scrap .MuiFilledInput-underline::before:hover {
  border-bottom: none !important;
}

.pp-new-scrap .MuiFilledInput-underline::after {
  width: 75%;
  margin: auto;
}

.pp-new-scrap .MuiInputAdornment-positionStart {
  margin-top: 0 !important;
}

.pp-scraps-container {
  min-height: 550px;
}

.social-links-text {
  overflow-wrap: anywhere;
}

@media screen and (min-width: 901px) {
  #content {
    margin-left: 82px;
    height: 100%;
    min-height: 800px;
    min-width: calc(100% - 82px);
  }

  .profilePageCard2 {
    position: absolute;
  }
}

@media screen and (max-width: 900px) {
  #content {
    margin-top: 60px;
    height: 180vh;
    min-height: 1400px;
    min-width: 100%;
  }

  .profilePageCard2 {
    width: 100%;
    position: absolute;
    height: 98vh;
    overflow-y: auto;
  }

  .pp-mobile-container {
    min-height: 98vh;
    margin: 0 10px;
    padding: 15px;
    border-radius: 16px !important;
    overflow: unset !important;
  }

  .pp-mobile-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }

  .pp-mobile-top-bar-right {
    display: flex;
  }

  .pp-mobile-user-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  .pp-mobile-top-bar svg {
    width: 25px;
    height: 25px;
  }

  .pp-mobile-user-photo {
    width: 117px !important;
    height: 117px !important;
  }

  .pp-mobile-user-name {
    font-size: 25px;
    font-weight: 500;
    margin-top: 12px;
  }

  .pp-mobile-user-dept {
    font-size: 16px;
    color: #828282;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .pp-mobile-user-quote {
    margin-top: 8px;
    text-align: center;
    font-size: 17px;
  }

  .pp-mobile-user-about {
    padding: 5px 10px;
    margin-top: 10px;
  }

  .pp-scraps-heading {
    margin-top: 10px;
  }

  .pp-mobile-user-tabs .MuiTabs-flexContainer {
    justify-content: center;
  }

  .pp-mobile-user-about .pp-user-bday-container {
    padding-bottom: 15px;
  }

  .pp-mobile-share-icon {
    margin-right: 25px;
  }

  .pp-scrap-likes-time-container,
  .like-count {
    font-size: 12px;
  }

  .pp-scrap-likes-time-container svg {
    width: 18px;
    height: 18px;
  }

  .pp-new-scrap {
    padding-bottom: 7px;
  }

  .pp-new-scrap .Mui-focused {
    margin-bottom: 20px;
  }

  .pp-user-email-icon,
  .pp-user-bday-icon {
    padding-right: 8px;
  }

  .pp-user-bday-container,
  .pp-user-email-container {
    gap: 0;
  }

  .pp-mobile-user-tabs .MuiTab-wrapper {
    padding: 0 35px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
