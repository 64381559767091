@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
.heading-college {
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 2.25rem;
  /* font-size: 1rem; */
  color: #000000;
  line-height: 1.25;
  padding-top: 20px;
}
.heading-dept {
  font-family: 'Inter', sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.1em;
  padding: 20px 0;
  color: #000;
  padding-bottom: 80px;
  /* font-size: 0.5rem; */
}

/* .makeStyles-gridList-2 {
  margin: auto !important;
} */
.MuiGridListTile-tile img {
  height: 100%;
  width: 100px;
  object-fit: cover;
  z-index: 100 !important;
}
.MuiGridList-root {
  margin: auto !important;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
}
.MuiGridListTile-root {
  width: auto !important;
  padding: 0 !important;
}
.refContainer {
  width: 100%;
  margin: auto;
  text-align: center;
}
.deptContainer {
  background: rgba(108, 108, 108, 0.05);
  border: 6px solid rgba(108, 108, 108, 0.4);
  border-radius: 16px;

  box-sizing: border-box;
  display: inline-block;
  margin: 4vh 10vw 10vh 10vw;

  height: auto;
  padding: 11vmin;
  position: relative;
  text-align: center;
}
/* .deptContainer:before {
  border-radius: 3px;
  bottom: -2.5vmin;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.123) inset;
  content: '';
  left: -2.5vmin;
  position: absolute;
  right: -2.5vmin;
  top: -2.5vmin;
} */

.buttonContainer {
  text-align: center;
  margin: 30px;
}
.buttonContainer button {
  border-radius: 34px;
  text-transform: none;
  font-size: 20px;
  margin-right: 20px;
}
.loadingSchool {
  font-size: 75px !important;
}
.MuiCircularProgress-colorPrimary {
  color: #3caea3 !important;
}
#loading {
  font-size: 40px;
  border: 1px solid rgba(0, 0, 0, 0.644);
  color: #fff;
}

.dept-college-logo {
  max-width: 240px;
  margin: auto;
  padding-bottom: 10px;
}
.content-dept-pic {
  text-align: center;
  padding-top: 50px;
}
.content-dept-pic h1 {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}
.content-dept-pic p {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 8%;
  padding-top: 16px;
  padding-bottom: 20px;
  color: #6c6c6c;
}

#save-button svg {
  color: white;
}

.dept-pic-single-image {
  width: 100px;
  height: 140px;
}
.gradGogglesLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  margin-bottom: -6vmin;
}
.gradGogglesLogo h6 {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8px;
}
.dept-pic-note {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  color: #777;
  text-align: center;
}
#vit {
  max-width: 100px;
}
#seema_d {
  max-width: 120px;
}
@media only screen and (max-width: 1032px) {
  .deptContainer {
    margin: 4vh 5vw 10vh 5vw;
    height: auto;
    padding: 8vmin;
  }
  .gradGogglesLogo {
    margin-bottom: -4vmin;
  }
}
@media only screen and (max-width: 767px) {
  .heading-college {
    font-size: 1.875rem;
  }
  .MuiGridListTile-tile img {
    height: 100%;
    width: 30px;
    object-fit: cover;
    z-index: 100 !important;
  }
  .heading-dept {
    padding-bottom: 20px;
  }
  .makeStyles-gridList-2 {
    padding-bottom: 30px !important;
  }

  .dept-pic-single-image {
    width: 39px;
    height: 54px;
  }

  .buttonContainer button {
    margin-bottom: 15px;
  }

  .gradGogglesLogo {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 630px) {
  .heading-college {
    font-size: 1.5rem;
  }
  .dept-college-logo {
    margin-top: 8vmin;
  }
  .heading-dept {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 550px) {
  .deptContainer {
    padding: 0;
  }
  .gradGogglesLogo {
    margin-bottom: 10px;
    padding-top: 0;
  }
}
