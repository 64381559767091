@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');

.formHalf {
  display: flex;
  height: 100%;
}
.loginSignUpHolder::before {
  content: ' ';
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background: linear-gradient(99.2deg, #582bee -1%, #d95ff2 98.15%);
  z-index: -3;
}
.joinHeading {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4% 0;
}

.logoName {
  display: flex;
  flex-direction: row;
  padding-left: 15%;
}

.joinBody {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joinContainer {
  height: 100vh;
}

.login-bg {
  position: absolute;
  z-index: -2;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  transition: opacity 0.5s ease;
}

.joinContainer::before {
  content: ' ';
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
}

.heading-greet {
  color: #ffffff !important;
  align-items: center;
  padding-left: 15%;
  padding-right: 10%;
  font-size: 3rem !important;
  /* line-height: 4.563rem !important; */
  font-family: 'Inter', sans-serif;
  justify-content: left;
}

.auth-college-name {
  color: white;
  padding-left: 15%;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.auth-select-college {
  border: 1px solid #aeaeae;
  padding: 5px;
}

.auth-college-logo {
  padding-left: 15%;
  max-width: 50%;
}

.formHalf .MuiTypography-h6 {
  color: white;
  padding-left: 15%;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.joinBodyInner {
  width: 70%;
  min-height: 65%;
  background-color: white;
  padding: 5% 5%;
  border-radius: 8px;
}

.joinBodyInner div {
  padding: 5px;
}

.joinBody .MuiOutlinedInput-input {
  padding: 5px 8px !important;
}

.joinBody .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.logButtonHolder {
  text-align: center;
  margin-top: 20px;
}

.logButtonHolder .MuiButton-outlined,
.next-btn button {
  padding: 5px 0;
  background-color: #582bee;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.googleButton {
  text-align: center;
}

.googleButton .MuiButton-outlined {
  padding: 5px 0;
  background-color: #ffffff;
  color: #000000;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.logButtonHolder button:hover,
.next-btn button:hover {
  border-color: #582bee;
  color: #000000;
}

#orSpan {
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 0;
}

.signUpChanger {
  margin-bottom: 20px;
  margin-top: 25px;
}

.signUpChanger a {
  color: #582bee;
}

.arrowBack {
  cursor: pointer;
}

#bday-header {
  padding-bottom: 0;
  margin-top: 20px;
}

.dobHolder div {
  padding: 0;
  margin-top: 0;
  /* border: 1px solid red; */
}

.next-btn {
  margin-top: 20px;
}

.photo-Container {
  display: flex;
  width: 100%;
}

.photo-ContainerLeft {
  width: 50%;
  padding: 10px !important;
}

.photo-ContainerRight {
  width: 50%;
}

.photo-ContainerRight .MuiTypography-body1 {
  font-size: 14px;
  color: #6c6c6c;
  font-family: 'Inter', sans-serif;
  padding-top: 2px;
  margin-right: -50px;
}

.quoteHolder {
  margin-top: -20px;
}

#qouteArea {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #828282;
  border-radius: 8px;
  padding: 10px;
  height: 70px !important;
  resize: none !important;
}

.loginAvatarHolder .makeStyles-formControl-16,
.loginAvatarHolder .makeStyles-formControl-26 {
  margin: 0;
}

#register-final {
  margin-top: 50px !important;
}

#fullname,
#graduateDate {
  display: flex;
}

.joinBodyInner {
  position: relative;
}

.formHalf .MuiSvgIcon-root {
  color: #828282;
}

.joinBodyInner .arrowBack {
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
}

.arrowBackMobile {
  display: none !important;
}

.arrowBack {
  display: block !important;
}

@media (max-width: 1034px) {
  .joinBodyInner {
    width: 90%;
  }
}

@media (max-width: 895px) {
  .joinBodyInner {
    width: 90%;
  }

  #dept-pic {
    margin-right: -20px;
  }
}

/* Mobile View Layout */
@media (max-width: 789px) {
  .formHalf {
    display: block;
    overflow-x: hidden;
    height: 100%;
    /* text-align: center; */
    margin: auto;
  }

  .logoName {
    display: none;
  }

  .arrowBack {
    display: none !important;
  }

  .joinHeading {
    width: 100%;
  }

  .joinHeading h6, .auth-college-logo {
    display: none;
  }

  .joinBody {
    width: 100%;
    flex-direction: unset;
    min-height: 78.5%;
  }

  .heading-greet {
    padding-left: 7%;
    padding-right: 3%;
    text-align: left;
    font-family: 'Inter', sans-serif !important;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
  }

  .arrowBack {
    display: none;
  }

  .arrowBackMobile {
    display: block !important;
    margin-bottom: 20px;
    margin-left: 7%;
  }
}

@media (max-width: 364px) {
  .joinBodyInner {
    width: 95%;
  }

  .arrowBackMobile {
    margin-left: 3%;
  }

  .heading-greet {
    padding-left: 3%;
  }
}
