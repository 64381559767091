.yb-cards {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2.75rem;
    justify-content: center;
}

.yb-card {
    flex-basis: 21%;
    margin: 20px;
    display: flex;
    justify-content: center;
}

.yb-card-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    background: #e2e2e2;
    border-radius: 13px;
}

.yb-card-photo {
    position: relative;
}

.yb-card-quoname-container {
    position: absolute;
    bottom: 11px;
    padding: 0 10px;
    color: white;
}

.yb-card-user-name {
    color: white;
    font-size: 22px;
    font-weight: 500;
}

.yb-card-photo img {
    width: 250px;
    height: 350px;
    object-fit: cover;
    border-radius: 13px;
}

.yb-card-bg {
    position: absolute;
    width: 100%;
    border-radius: 13px;
    height: 50%;
    top: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.95) 100%);
    transition: all 0.2s ease;
}

.yb-card-bg-hovered{
    height: 60%;
    top: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.95) 100%);
}

.yb-card-share-star-container {
    position: absolute;
    display: flex;
    gap: 15px;
    top: 15px;
    right: 15px;
}

.yb-card-icon{
    fill: white !important;
    height: 30px !important;
    width: 30px !important;
}

.yb-card-icons-bg{
    position: absolute;
    width: 100%;
    border-radius: 13px;
    height: 0;
    top: 0;
    transition: all 0.3s ease;
}

.yb-card-icons-bg-hovered{
    height: 80%;
    background: linear-gradient(45deg, rgba(255,255,255,0) 68%, rgba(0,0,0,0.75) 100%);
}

.icons-hidden{
    opacity: 0;
    transition: all 0.3s ease;
}

.icons-visible{
    opacity: 1;
}