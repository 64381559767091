.searchContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'searchHeading' 'searchContent';
}

.searchHeading {
  font-size: 27px;
  place-self: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1rem;
  color: #000;
  text-transform: uppercase;
  float: left;
}

@media (max-width: 500px) {
  .searchHeading {
    place-self: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2rem;
    color: #000;
    text-transform: uppercase;
    float: left;
  }

  .searchFilterArea {
    width: 100%;
    padding-right: 2%;
    padding-top: 1%;
    border-bottom: 1px solid #f1f1f1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50% 50%;
    grid-template-areas: 'searchSelectBox', 'searchBoxInResult';
  }

  .searchSelectBox {
    justify-self: center;
    width: 300px;
    margin-bottom: 1rem;
  }

  .searchBoxInResult {
    justify-self: center;
    width: 300px;
    margin-top: 1rem;
  }
}

@media (min-width: 501px) {
  .searchFilterArea {
    width: 100%;
    padding-right: 2%;
    padding-top: 1%;
    border-bottom: 1px solid #f1f1f1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: 'searchSelectBox', 'searchBoxInResult';
  }

  .searchSelectBox {
    justify-self: left;
  }

  .searchBoxInResult {
    justify-self: right;
  }
}

.noResultsFound {
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin: 2rem auto;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.searchContent {
  margin: 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  grid-template-areas: 'searchFilterArea' 'searchCardArea' 'searchPaginationArea';
  float: left;
  position: relative;
}

.searchPaginationArea {
  justify-self: center;
}

#searchBackDrop {
  background: #f1f1f1;
  height: 100%;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #3caea3 !important;
  color: #fff !important;
}

@media (min-width: 901px) {
  #searchBackDrop {
    height: 100%;
    margin-left: 82px;
  }
}

@media (max-width: 900px) {
  #searchBackDrop {
    height: 100%;
    margin-top: 60px;
  }
}

.backdropIn {
  z-index: 2000 !important;
  color: #fff;
}
