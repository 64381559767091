.collegeSelectHero {
    appearance: none;
    background: #a0aec012;
    border-color: #00000047;
    border-width: 1px !important;
}

.collegeSelectHero:hover {
    border-color: #a0aec0 !important;
}

.partnerColleges {
    display: flex;
    margin-top: 15px;
}

.partnerCollege {
    color: #a0aec0;
    border: 1px solid #a0aec0;
    padding: 7px;
    margin: 0 5px;
    border-radius: 15px;
    transition: all 0.2s ease-in;
}

.partnerCollege:hover {
    background: #e1e7ea;
    color: #8e959f;
    cursor: default;
}

.gg-your-college {
    margin-top: 15px;
    color: #582BEE;
    cursor: pointer;
    width: max-content;
    transition: all 0.1s ease;
    font-size: 0.85rem;
}

.gg-your-college:hover {
    border-bottom: 1px solid;
}

.mobile-nav-close {
    position: absolute;
    top: 15px;
    left: 15px;
}

.headerSignUpLink {
    background: #582BEE !important;
    color: white !important;
    transition: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    margin-top: -7px !important;
}

.headerSignUpLink:hover {
    background: #2b00b7 !important;
}

.hero-decorator-blob-1 {
    height: 27rem;
    width: 38rem;
    --transform-translate-x: -15.67% !important;
    --transform-translate-y: -29% !important;
}

.hero-decorator-blob-2 {
    height: 27rem;
    width: 38rem;
    --transform-translate-x: 26.33% !important;
    --transform-translate-y: 24% !important;
}

@media screen and (max-width: 900px) {
    .partnerColleges {
        justify-content: center;
    }

    .coming-soon-mobile {
        text-align: center !important;
    }

    .gg-your-college {
        margin: 15px auto 0 auto;
    }

    .hero-decorator-blob-1 {
        --transform-translate-x: -40.67% !important;
        --transform-translate-y: -63% !important;
    }

    .hero-decorator-blob-2 {
        display: none;
    }

    .features-section-contaienr {
        margin-left: -17px !important;
    }
}

@media screen and (min-width: 1400px) {
    .hero-decorator-blob-2 {
        display: none;
    }
}