.header-notification-icon {
    margin-right: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tutorial-text {
    text-align: justify;
}

.header-notification-popover .MuiPopover-paper {
    max-width: 500px;
}

.notif-loader {
    width: 500px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notif-loader-text {
    margin-left: 10px;
}

.notif-single-action {
    padding: 15px !important;
    display: flex !important;
}

.notif-image {
    margin-right: 10px;
}

.notif-image .MuiAvatar-root {
    width: 45px;
    height: 45px;
}

.notif-body {
    flex: 1;
}

.yb-search-box .MuiInputAdornment-root {
    margin-top: 0 !important;
}

.yb-search-box input {
    padding: 10px 12px 10px;
    border-radius: 100px;
}

.yb-search-box .MuiFilledInput-adornedStart {
    border-radius: 110px;
}

.yb-search-box .MuiFilledInput-underline::before {
    border-bottom: none !important;
}

.yb-search-box .MuiFilledInput-underline::before:hover {
    border-bottom: none !important;
}

.yb-search-box .MuiFilledInput-underline::after {
    width: 90%;
    margin: auto;
}

.yb-search-box .MuiFormControl-root {
    background: #fcfcfc;
    border-radius: 110px;
}

.yb-search-box .Mui-focused {
    background: #fcfcfc !important;
}

.yb-search-box .MuiFilledInput-root,
.yb-search-box .MuiFilledInput-root {
    background: #efefeff2;
}

.yb-search-box .MuiFilledInput-root:hover,
.yb-search-box .MuiFilledInput-root:active,
.yb-search-box .MuiFilledInput-root:focus {
    background: #fcfcfc;
}

.yb-search-box .MuiInputBase-input:focus {
    background: #fcfcfc !important;
}

.yb-college-section {
    min-height: 48vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.yb-clg-name {
    font-size: 20px;
    color: white;
    padding-bottom: 15px;
    text-align: center;
}

.yb-batch-name {
    font-size: 37px;
    color: white;
    font-weight: 700;
    padding-bottom: 23px;
}

.yb-header-mobile-logo,
.yb-header-mobile {
    display: none !important;
}

.yb-dept-chip {
    border: 1px solid #96969696 !important;
}

.yb-dept-chip:hover {
    background: #582bee57 !important;
    border: 1px solid #582bee !important;
    color: black !important;
}

.dept-chips-left-scroll {
    position: absolute;
    left: 0;
    width: 100px;
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
    z-index: 10000;
}

.dept-chips-right-scroll {
    position: absolute;
    right: 0;
    width: 100px;
    display: flex;
    justify-content: flex-end;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
}

.yb-dept-chip {
    margin: 0 6px !important;
}

.dept-chips-scroll-container {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100vw;
    scroll-behavior: smooth;
}

.dept-chips-left-arrow, .dept-chips-right-arrow {
    color: black !important;
    background: #582bee3b;
}

.dept-chips-wrapper {
    display: flex;
    padding: 6px 30px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
}

.loading-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12vh 0;
    color: #777;
}

.loading-more-text{
    font-size: 15px;
}

@media only screen and (max-width: 768px) {
    .header-notification-icon {
        margin-right: 0;
    }

    .yb-header {
        padding: 0 1rem !important;
    }

    .yb-header-mobile-logo {
        display: block !important;
        margin-top: 0.3rem !important;
        margin-bottom: 0 !important;
        margin-left: 55px !important;
    }

    .notif-loader {
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .yb-header-mobile {
        display: block !important;
    }

    .dept-chips-scroll-container {
        padding: 10px 0;
        overflow: auto !important;
    }

    .dept-chips-wrapper {
        padding: 0 !important;
        flex-wrap: nowrap !important;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
    }

    .dept-chips-wrapper::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    .yb-cards {
        padding: 0 !important;
    }

    .yb-card {
        margin: 5px !important;
    }

    .mobile-nav-close {
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .loading-more-text{
        padding: 25px;
        text-align: center;
    }
}
