.yearBookContainer {
    display: grid;
    animation: fadeIn 2s;
}

.yearBookHeading {
    font-size: 27px;
    place-self: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1rem;
    color: #000;
    text-transform: uppercase;
    float: left;
}

@media (max-width: 500px) {
    .yearBookHeading {

        place-self: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2rem;
        font-style: bold;
        color: #000;
        text-transform: uppercase;
        float: left;
    }

    .filterArea {
        width: 100%;
        padding-right: 2%;
        padding-top: 1%;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 50% 50%;
        grid-template-areas: "selectBox","searchBox";
    }

    .selectBox {
        justify-self: center;
        width: 300px;
        margin-bottom: 1rem;

    }

    .searchBox {
        justify-self: center;
        width: 300px;
        margin-top: 1rem;
    }

    .internals {
        width: 100%
    }

}

@media (min-width: 501px) and (max-width: 700px) {
    .internals {
        width: 220px
    }
}

@media (min-width: 701px) {
    .internals {
        width: 300px
    }
}

@media (min-width: 501px) {
    .filterArea {
        width: 100%;
        padding-right: 2%;
        padding-top: 1%;
        border-bottom: 1px solid #f1f1f1;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: "selectBox","searchBox";
    }

    .selectBox {
        justify-self: left;
    }

    .searchBox {
        justify-self: right;
    }
}

.yearBookContent {

    margin: 1rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "filterArea" "yearbookCardArea" "paginationArea";
    float: left;
    position: relative;
}


.paginationArea {
    justify-self: center;
}

#yearBookBackDrop {
    background: #f1f1f1;
    height: 100%;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: #3caea3 !important;
    color: #fff !important;
}


@media (min-width: 901px) {
    #yearBookBackDrop {
        height: 100%;
        margin-left: 82px;

    }
}

@media (max-width: 900px) {
    #yearBookBackDrop {
        height: 100%;
        margin-top: 60px;

    }
}


.backdropIn {
    z-index: 2000 !important;
    color: #fff
}