@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap);
/*Landing Section Div*/
@media screen and (min-width: 901px) {
    .main-sidebar + .main-menu + #homeContent {
        margin-left: 82px;
    }
}

@media screen and (max-width: 900px) {
    .main-sidebar + .main-menu + #homeContent {
        margin-top: 60px;
    }
}

#homeContent {
    zoom: 1;
}

#homeContent {
    display: block;
}

.landingSection {
    zoom: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

/*Left Div*/
@media screen and (min-width: 769px) {
    .landingSection .left {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 31.51694%;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left, .landingSection .right {
        width: 100%;
    }
}

.landingSection .left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 31.51694%;
}

.landingSection .left {
    background: #fff;
    color: #042f66;
}

.landingSection .left, .landingSection .right {
    float: left;
}

@media screen and (min-width: 769px) {
    .landingSection .left .icon {
        margin: 50px auto 0;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .icon {
        margin: 34px auto;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .icon {
        margin: 34px auto 0 !important;
    }
}


@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .title {
        margin: auto !important;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .icon {
        width: 250 !important;
    }
}

.landingSection .left .icon {
    display: block;
}

svg:not(:root) {
    overflow: hidden;
}

/*Section Title*/
@media screen and (min-width: 769px) {
    .landingSection .left .title {
        margin: 0 auto;
    }
}

@media (max-width: 1439px) and (min-width: 824px) {
    .landingSection .left .title {
        font-size: 3.4vw;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .title {
        margin: 34px auto;
        padding-top: 0;
    }
}

@media (min-width: 769px) and (max-width: 823px) {
    .landingSection .left .title {
        font-size: 32.00556px;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .title {
        font-size: 50px;
    }
}

@media (min-width: 1440px) {
    .landingSection .left .title {
        font-size: 56px;
    }
}

.landingSection .left .title {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    text-align: center;
    line-height: 1.35;
    padding: 7.00935% 7.00935% 0;
}

/*Section Intro Div*/

.landingSection .left .section-intro {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .02em;
    color: #000;
}

.landingSection .left .itineraries {
    font-family: "Source", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
}

@media screen and (max-width: 1439px) and (min-width: 1081px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 1.11111vw;
    }
}

@media screen and (min-width: 1440px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 16px;
    }
}

@media screen and (max-width: 1080px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        font-style: normal;
        letter-spacing: .02em;
        color: #000;
    }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .landingSection .left .section-intro {
        font-size: 16px;
    }
}

@media screen and (max-width: 424px) and (max-width: 768px) and (min-width: 320px) {
    .landingSection .left .section-intro {
        font-size: 3.76471vw;
    }
}

@media screen and (max-width: 319px) and (max-width: 768px) {
    .landingSection .left .section-intro {
        font-size: 12.00941px;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .section-intro {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: .02em;
        padding-top: 0;
        color: #000;
    }
}


.landingSection .left .section-intro {
    color: #000;
    width: 100%;
    padding: 7.00935% 7.00935% 0;
}

.landingSection .left .section-intro p {
    font-weight: 400;
}

.cms-text > :first-child {
    margin-top: 0;
}

.cms-text p {
    line-height: 1.325em;
    margin: 1.25em 0;
    text-align: justify;
}

/*Counter Divs*/

@media screen and (min-width: 1440px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 22px;
    }
}

@media screen and (max-width: 1439px) and (min-width: 1048px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 1.52778vw;
    }
}

@media screen and (max-width: 1047px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 15.99583px;
    }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .landingSection .left .itineraries {
        font-size: 22px;
    }
}

@media screen and (max-width: 424px) and (max-width: 768px) and (min-width: 310px) {
    .landingSection .left .itineraries {
        font-size: 5.17647vw;
    }
}

@media screen and (max-width: 309px) and (max-width: 768px) {
    .landingSection .left .itineraries {
        font-size: 15.99529px;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        font-style: bold;
        letter-spacing: 0.02em;
        text-transform: uppercase !important;
    }
}

.landingSection .left .itineraries {
    margin-bottom: auto;
    width: 100%;
    padding: 7.00935%;
}

.landingSection .left .itineraries .itineraries-heading {
    color: #000;
}

.landingSection .left .itineraries ul {
    margin: 7.00935% 0 0;
}

ul {
    padding-left: 0;
}

.landingSection .left .itineraries li {
    padding: .5em 0;
    border-bottom: 2px solid;
}

ul li {
    list-style: none;
    margin-left: 0;
}

.landingSection .section-2 .left .itineraries li:last-child {
    border-bottom: 0;
}

/*Right Div*/
@media screen and (min-width: 769px) {
    .landingSection .right {
        width: 68.48306%;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left, .landingSection .right {
        width: 100%;
    }
}

.landingSection .right {
    position: relative;
    background: #042f66;
}

.landingSection .right .images {
    zoom: 1;
}


.landingSection .right .images .image {
    float: left;
    visibility: hidden;
    width: 100%;
    margin-right: -100%;
}

.responsive.image {
    overflow: hidden;
}

.responsive.image .aspect-maintainer {
    height: 0;
    position: relative;
    padding-top: 73.118280%;
    width: 100%;
}


.responsive.image .aspect-maintainer > div {
    bottom: -1px;
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
}

.responsive.image.restrict-width:not(.centered) img {
    width: 100%;
}

.responsive.image.restrict-width img {
    height: auto;
    max-width: 100%;
    border: 0;
}

.landingSection .right .images:after {
    clear: both;
    content: '';
    display: table;
}

/*Nav Table*/

.landingSection .right .category-links {
    zoom: 1;
    position: relative;
}

.landingSection .right .category-links .category-item {
    float: left;
    color: #f5f4ed;
    text-align: center;
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current {
        color: #042f66;
        background: #f5f4ed;
    }
}


#numeroUno {
    width: 25%;
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .category-item.current {
        color: #042f66;
        background-color: #fff;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .category-item {
        width: 50% !important;
    }
}


.landingSection .right .category-links .hover-link {
    display: block;
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

a {
    background-color: transparent;
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title {
        padding: 12.90323% 12.90323% 0;
    }
}

@media (max-width: 960px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .hover-link .title {
        padding: 17px 17px 0;
    }
}

@media (max-width: 1439px) and (min-width: 961px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 1.66768vw;
    }
}


@media (min-width: 1440px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 24px;
    }
}

.landingSection .right .category-links .hover-link .title {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    position: relative;
    display: block;
    margin: 0 0 0;
    line-height: 1.15;
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current .title::before {
        border-left: 10px solid transparent;
        border-bottom: 7px solid #f5f4ed;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::before {
        width: 7px;
        right: 50%;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::before, .landingSection .right .category-links .hover-link .title::after {
        content: '';
        position: absolute;
        bottom: 100%;
        border-bottom: 7px solid transparent;
    }
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current .title::after {
        border-right: 10px solid transparent;
        border-bottom: 7px solid #f5f4ed;
    }
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::after {
        left: 50%;
        width: 7px;
    }
}

.landingSection .right .category-links .hover-link .title span {
    display: inline-block;
    min-height: 2.25em;
}

/*Icon Table*/

@media screen and (min-width: 1281px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 12.90323% 5%;
    }
}

.landscape {
    opacity: 0.9;
    filter: brightness(72%);
    -webkit-filter: brightness(72%);
    -moz-filter: brightness(72%);
}

@media screen and (max-width: 1280px) and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 25px 25px;
    }
}


@media screen and (max-width: 768px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 51px 51px;
    }
}

.landingSection .right .category-links .hover-link .icon-wrap {
    height: 120px;
}

/*@media screen and (min-width: 769px){.landingSection .right .category-links .category-item.current .icon-wrap .icon {*/
/*    filter: invert(70%) sepia(70%) saturate(444%) hue-rotate(342deg) brightness(107%) contrast(101%)!important;*/
/*}}*/


.landingSection .right .category-links .hover-link .icon-wrap .icon {
    display: block;
    margin: auto;
    height: 100%;
    width: 100%;
    fill: #f5f4ed;
}

/*Panelless*/
.panelless-transition {
    position: relative;
    transition: opacity 1000ms, visibility 1000ms;
    opacity: 1;
    visibility: inherit;
    z-index: 2;
}

.panelless-transition, .standard-transition {
    margin-right: -100%;
    float: left;
    width: 100%;
}

.footer {

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    text-align: center;
    background: #fff;
    color: #042f66;
    border-top: 1px solid #042f66;
    line-height: 50px;
    height: 50px;

}

#homeContent:after {
    clear: both;
    content: '';
    display: table;
}

/*Item Text*/
.landingSection .right .category-links .category-item-text {
    zoom: 1;
    position: absolute;
    bottom: 100%;
    left: 0;
    visibility: hidden;
    text-align: left;
    width: 100%;
    padding-bottom: 20px;
}

@media (max-width: 1439px) and (min-width: 721px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 1.94444vw;
    }
}

.landingSection .right .category-links .category-item-text .heading {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    width: 36.55914%;
    float: left;
    padding: 0 20px;
    margin: 0;
    color: #ffffff;
}

@media (max-width: 720px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 14px;
    }
}


@media (min-width: 1440px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 28px;
    }
}

.landingSection .right .category-links .category-item-text .cms-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: thin;
    letter-spacing: .02em;
    font-size: 13px;
    float: left;
    width: 63.44086%;
    max-width: 450px;
    padding-right: 20px;
    color: #fff;
}

.cms-text > :first-child {
    margin-top: 0;
}

.cms-text p {
    line-height: 1.325em;
    margin: 1.25em 0;
}

.cms-text > :last-child {
    margin-bottom: 0;
}

.landingSection .right .category-links .category-item-text:after {
    clear: both;
    content: '';
    display: table;
}

@media screen and (min-width: 768px) {
    .landingSection .right .category-links .category-item.current .category-item-text {
        visibility: inherit;
    }
}

.landingSection .right .category-links .category-item-text .cms-text .explore {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    font-size: 15px;
    background-image: none;
    text-decoration: none;
    display: inline-block;
    color: #042f66;
    padding: .5em 1em;
    border: 2px solid;
}

.landingSection .right .images .image.default, .landingSection .right .images .image.current {
    visibility: visible;
}

/*Second Nav Item*/
#numeroDos {
    width: 25%;
}

#numeroTres {
    width: 25%;
}

#numeroCuatro {
    width: 25%;
}

.getStartedButton {
    border: black solid 3px !important;
    background-color: transparent !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #042f66 !important;
}

.getStartedButton:hover {
    border: black solid 3px !important;
    background-color: #042f66 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #fff !important;
}

.playLink {
    color: #194c8e !important;
}

.googleplay {
    height: 63px;
    transition: all 0.2s ease-in;
}

.googleplay:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.homepageButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.like-count {
  text-align: center;
  margin-left: 2px;
  font-size: 14px;
}

.like-icon {
  padding: 3px 9px !important;
}

.profilePageCard2 {
  border-radius: 15px;
}

.loadingSchool {
  color: #3caea3;
  font-size: 75px !important;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

.dialogHeadingCSS {
  font-weight: 700;
  font-size: 20px;
}

.dialogBodyCSS {
  font-weight: 600;
  font-size: 15px;
}

.dialogCloseCSS {
  color: #3caea3 !important;
}

.pp-lhs-img-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

.pp-lhs-img-wrapper img {
  height: 85%;
  width: 85%;
  object-fit: cover;
  border-radius: 15px;
}

.pp-history-back {
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 16px;
}

.pp-rhs-card {
  height: 100%;
  padding: 25px 25px 0 25px !important;
  overflow: auto;
}

.pp-user-info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.pp-user-name-dept {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.pp-user-name {
  font-size: 32px;
  font-weight: 500;
}

.pp-user-dept {
  font-size: 16px;
  color: #828282;
}

.pp-user-details-tabs svg {
  color: #582bee;
}

.pp-share-star-icons {
  display: -webkit-flex;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 15px;
  min-width: 70px;
}

.pp-share-icon svg {
  cursor: pointer;
}

.pp-share-star-icons svg {
  width: 24px;
  height: 26px;
  color: #582bee;
}

.pp-user-bday-container,
.pp-user-email-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #828282;
  grid-gap: 8px;
  gap: 8px;
}

.pp-user-bday-container svg,
.pp-user-email-container svg {
  width: 25px;
  height: 24px;
}

.pp-user-details-tabs .MuiAppBar-colorPrimary {
  color: #000;
  background-color: white;
  box-shadow: none;
  margin-top: 24px;
}

.pp-user-details-tabs .MuiButtonBase-root {
  text-transform: none;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.pp-user-details-tabs .MuiButtonBase-root:focus {
  outline: none;
}

.pp-user-details-tabs .MuiTabs-indicator {
  display: none;
}

.pp-user-details-tabs .Mui-selected {
  background: #efefef;
  border-radius: 50px;
  padding: 0 !important;
  font-weight: 500;
  transition: all 0.15s ease;
}

.pp-user-no-socials {
  color: #828282;
  padding: 25px 5px 0 5px;
  text-align: center;
}

.pp-user-about-quote {
  display: -webkit-flex;
  display: flex;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
  grid-gap: 10px;
  gap: 10px;
  color: #828282;
}

.pp-user-about {
  display: -webkit-flex;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.pp-scraps-none {
  padding: 25px 0;
  color: #919191;
  min-height: 40vh;
}

.pp-scraps-heading {
  font-size: 21px;
  font-weight: 500;
  margin-top: 40px;
}

.pp-scrap-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 11px 0;
}

.pp-scrap-photo-name {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  max-width: 100px;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 4px;
  margin-right: 5px;
}

.pp-scrap-photo .MuiAvatar-root {
  height: 45px;
  width: 45px;
}

.pp-scrap-name {
  font-size: 15px;
  font-weight: 600;
  width: 80px;
  text-align: center;
  cursor: pointer;
}

.pp-scrap-content {
  font-size: 15px;
  background: #efefef;
  color: #3a3a3a;
  padding: 12px 15px;
  border-radius: 17px;
  position: relative;
  word-wrap: anywhere;
}

.pp-scrap-time {
  color: #828282;
}

.pp-scrap-content-container {
  position: relative;
  width: 100%;
}

.pp-scrap-likes-time-container {
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding-top: 5px;
  margin-left: -10px;
  color: #582bee;
}

.pp-scrap-likes-time-container svg {
  height: 21px;
  width: 21px;
  color: #582bee;
}

.pp-user-socials {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 20px;
  color: #828282;
}

.pp-user-social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.pp-user-socials .pp-user-email-container svg {
  width: 28px;
  height: 24px;
}

.pp-new-scrap {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background: white;
  padding-bottom: 25px;
}

.pp-new-scrap .MuiFormControl-root {
  width: 100%;
  background: #c4c4c43d;
}

.pp-new-scrap .MuiFilledInput-input {
  padding: 0 5px;
}

.pp-new-scrap .MuiFilledInput-multiline {
  padding: 15px;
}

.pp-new-scrap .MuiFilledInput-root,
.pp-new-scrap .MuiFormControl-root {
  border-radius: 15px;
}

.pp-new-scrap .MuiFilledInput-underline::before {
  border-bottom: none !important;
  display: none;
}

.pp-new-scrap .MuiFilledInput-underline::before:hover {
  border-bottom: none !important;
}

.pp-new-scrap .MuiFilledInput-underline::after {
  width: 75%;
  margin: auto;
}

.pp-new-scrap .MuiInputAdornment-positionStart {
  margin-top: 0 !important;
}

.pp-scraps-container {
  min-height: 550px;
}

.social-links-text {
  overflow-wrap: anywhere;
}

@media screen and (min-width: 901px) {
  #content {
    margin-left: 82px;
    height: 100%;
    min-height: 800px;
    min-width: calc(100% - 82px);
  }

  .profilePageCard2 {
    position: absolute;
  }
}

@media screen and (max-width: 900px) {
  #content {
    margin-top: 60px;
    height: 180vh;
    min-height: 1400px;
    min-width: 100%;
  }

  .profilePageCard2 {
    width: 100%;
    position: absolute;
    height: 98vh;
    overflow-y: auto;
  }

  .pp-mobile-container {
    min-height: 98vh;
    margin: 0 10px;
    padding: 15px;
    border-radius: 16px !important;
    overflow: unset !important;
  }

  .pp-mobile-top-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 15px;
  }

  .pp-mobile-top-bar-right {
    display: -webkit-flex;
    display: flex;
  }

  .pp-mobile-user-intro {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 25px;
  }

  .pp-mobile-top-bar svg {
    width: 25px;
    height: 25px;
  }

  .pp-mobile-user-photo {
    width: 117px !important;
    height: 117px !important;
  }

  .pp-mobile-user-name {
    font-size: 25px;
    font-weight: 500;
    margin-top: 12px;
  }

  .pp-mobile-user-dept {
    font-size: 16px;
    color: #828282;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .pp-mobile-user-quote {
    margin-top: 8px;
    text-align: center;
    font-size: 17px;
  }

  .pp-mobile-user-about {
    padding: 5px 10px;
    margin-top: 10px;
  }

  .pp-scraps-heading {
    margin-top: 10px;
  }

  .pp-mobile-user-tabs .MuiTabs-flexContainer {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .pp-mobile-user-about .pp-user-bday-container {
    padding-bottom: 15px;
  }

  .pp-mobile-share-icon {
    margin-right: 25px;
  }

  .pp-scrap-likes-time-container,
  .like-count {
    font-size: 12px;
  }

  .pp-scrap-likes-time-container svg {
    width: 18px;
    height: 18px;
  }

  .pp-new-scrap {
    padding-bottom: 7px;
  }

  .pp-new-scrap .Mui-focused {
    margin-bottom: 20px;
  }

  .pp-user-email-icon,
  .pp-user-bday-icon {
    padding-right: 8px;
  }

  .pp-user-bday-container,
  .pp-user-email-container {
    grid-gap: 0;
    gap: 0;
  }

  .pp-mobile-user-tabs .MuiTab-wrapper {
    padding: 0 35px;
  }
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.current-pp-min-h{
    min-height: 85vh !important;
}

.current-pp-card{
    position: relative;
}

.current-pp-scrap-visible-loading .MuiCircularProgress-root {
    height: 21px !important;
    width: 21px !important;
}
.editProfileButtonArea {
    width: 100%;
    height: 20%;
    text-align: center;
    margin: 0.5rem;
}

.editProfileButton {
    border-radius: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: italic;
    font-weight: 500;
    background-color: #3caea3;
}

.cp-avatar-wrapper {
    padding: 20px;
}

.avatarContainerDiv {
    cursor: pointer;
    object-fit: cover;
    width: 100px;
    height: 140px;
    border-radius: 8px;
    position: relative;
    margin: auto;
    padding-top: 10px;
}

.avatarContainerDiv img {
    cursor: pointer;
    object-fit: cover;
    width: 100px;
    height: 140px;
    border-radius: 8px;
}

.cp-avatar-label{
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
}

.avatarContainerDiv .fa.fa-edit {
    position: absolute;
    top: 8px;
    right: -7px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: white;
    color: #582bee;
    box-shadow: 0 0 8px 1px #B8B8B8;
    cursor: pointer;
}

.editProfileTextFieldsContainer {
    width: 40%;
    margin: auto;
}

.editProfileTextField {
    width: 40%;
}

.avatarHolder {
    -webkit-justify-content: center;
            justify-content: center;
    margin: 1rem;
    display: -webkit-flex;
    display: flex;
}

.formHolder {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.formSM {
    margin: 0.65rem 0;
    width: 84%;
}

.formSM svg {
    color: #979797;
}

.editSM {
    width: 100%;
}

.editEmail, .editPassword {
    margin: 0.5rem !important;

}

.rowOneHolder {
    width: 100%;
    text-align: center;
}

.rowTwoHolder {
    text-align: center;
    width: 100%;
    padding-right: 0px;
}

.selectDept {
    width: 43%;
    margin: 0.5rem !important;
}

.editSelect {

    margin: 0.5rem !important;
}

.editDatePicker.MuiFormControl-marginNormal {
    margin: 0.5rem !important;
    padding-top: 16px !important;

}

.formHolderSomeBody {
    width: 84%;
    margin: auto;
    padding-top: 10px;
}

.textAreaLabel {
    place-self: center;
    grid-column: 2/3;
    width: 100%;
    text-align: center;
}

.textAreaInside {
    grid-column: 2/3;
    width: 100%
}

.editScrapArea {
    width: 100%;
    height: 80%;
    overflow-y: auto;
    margin: 1rem;
}


#editScrapArea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#editScrapArea::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#editScrapArea::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background: rgba(60, 174, 163, 0.9); /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9));
}

.dontShowIcon {
    display: none !important;
}

.showIcon {
    display: inline-block !important;
}

.hiddenInputEdit {
    display: none !important;
}

.MuiDialog-root {
    z-index: 1800 !important;
}

.MuiPopover-root {
    z-index: 1900 !important;
}

.changePasswordHolder, .currentPasswordHolder, .newPasswordHolder, .changePasswordButtonHolder {
    display: grid;
    place-items: center;
}

.changePasswordHolder {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.currentPasswordHolder, .newPasswordHolder, .changePasswordButtonHolder {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.loadingSchool {
    color: #3caea3;
    font-size: 75px !important;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}

.topSMForm {
    margin-top: 10px;
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0
    }
}

@keyframes blinker {
    50% {
        opacity: 0
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@media screen and (max-width: 900px) {
    .formHolderSomeBody{
        width: 70%;
        padding: 25px 0;
    }
    .selectDept{
        margin-left: -20px !important;
    }
    .current-pp-mobile-container{
        border-radius: 0 !important;
        margin:0!important;
    }
    .rowTwoHolder, .rowOneHolder{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .editProfileTextFieldsContainer{
        width: 100%;
    }
    .editProfileTextField{
        width: 45%;
    }
    .formHolderSomeBody{
        width: 95%;
    }
    .formSM{
        width: 94%;
    }
    .topSMForm{
        margin-top: 0;
    }
}
.yearBookContainer {
    display: grid;
    -webkit-animation: fadeIn 2s;
            animation: fadeIn 2s;
}

.yearBookHeading {
    font-size: 27px;
    place-self: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1rem;
    color: #000;
    text-transform: uppercase;
    float: left;
}

@media (max-width: 500px) {
    .yearBookHeading {

        place-self: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2rem;
        font-style: bold;
        color: #000;
        text-transform: uppercase;
        float: left;
    }

    .filterArea {
        width: 100%;
        padding-right: 2%;
        padding-top: 1%;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 50% 50%;
        grid-template-areas: "selectBox","searchBox";
    }

    .selectBox {
        justify-self: center;
        width: 300px;
        margin-bottom: 1rem;

    }

    .searchBox {
        justify-self: center;
        width: 300px;
        margin-top: 1rem;
    }

    .internals {
        width: 100%
    }

}

@media (min-width: 501px) and (max-width: 700px) {
    .internals {
        width: 220px
    }
}

@media (min-width: 701px) {
    .internals {
        width: 300px
    }
}

@media (min-width: 501px) {
    .filterArea {
        width: 100%;
        padding-right: 2%;
        padding-top: 1%;
        border-bottom: 1px solid #f1f1f1;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: "selectBox","searchBox";
    }

    .selectBox {
        justify-self: left;
    }

    .searchBox {
        justify-self: right;
    }
}

.yearBookContent {

    margin: 1rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "filterArea" "yearbookCardArea" "paginationArea";
    float: left;
    position: relative;
}


.paginationArea {
    justify-self: center;
}

#yearBookBackDrop {
    background: #f1f1f1;
    height: 100%;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: #3caea3 !important;
    color: #fff !important;
}


@media (min-width: 901px) {
    #yearBookBackDrop {
        height: 100%;
        margin-left: 82px;

    }
}

@media (max-width: 900px) {
    #yearBookBackDrop {
        height: 100%;
        margin-top: 60px;

    }
}


.backdropIn {
    z-index: 2000 !important;
    color: #fff
}
.profileCardContainer {
    box-shadow: 2px 2px 4px 0px #000;
    width: 300px;
    height: 450px;
    background: #fff;
    border-radius: 50px;
    display: grid;
    grid-template-rows: 40% 60%;
    grid-template-columns: 100%;
    margin: 1rem auto;
    grid-template-areas: "photoContainer" "infoContainer";
}

.photoContainer {
    position: relative;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-template-areas: "avatarSection";
    background: #3caea3;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-bottom: 3%;
}

.infoContainer {
    position: relative;
    display: grid;
    grid-template-rows: 20% 55% 25%;
    grid-template-columns: 100%;
    grid-template-areas: "nameDeptSection" "quoteSection" "additionalSection";
    background: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-bottom: 10%;
}

.nameDeptSection {
    justify-self: center;

}

.nameDeptSection p {
    margin: 0;
    text-align: center;
}

.nameDeptSection .name {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 21px;
}

.nameDeptSection .dept {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 13px;
    font-style: bold;
}

.quoteSection {
    justify-self: center;
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-areas:"quoteIcon" "quote";
    grid-template-columns: 100%;
    overflow: overlay

}

.quoteSection .quoteIcon {
    justify-self: center;
    text-align: center;

}

.quoteSection p {
    margin: 0.5rem;
    text-align: justify;
}

.quoteSection .quote {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 18px;
}

.additionalSection {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "dob" "email";
}

.dob {
    justify-self: center;
    text-align: center;
}

.date {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin: 0.2rem;
    letter-spacing: 0.1rem;
}

.email {
    justify-self: center;
    text-align: center;
}

.address {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin-right: 1rem;
    margin-top: 0.2rem;
    overflow-wrap: break-word;
}

.editorial {
    display: block;
    width: 100%;
    height: 30px;
    max-height: 30px;
    margin: 0;
    z-index: 5;
    bottom: 0;
    position: absolute;
    left: 0px;
    float: left;
}

.parallax1 > use {
    -webkit-animation: move-forever1 10s linear infinite;
            animation: move-forever1 10s linear infinite;
}

.parallax1 > use :nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
}

.parallax2 > use {
    -webkit-animation: move-forever2 8s linear infinite;
            animation: move-forever2 8s linear infinite;
}

.parallax2 > use :nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
}

.parallax3 > use {
    -webkit-animation: move-forever3 6s linear infinite;
            animation: move-forever3 6s linear infinite;
}

.parallax3 > use :nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
}

.parallax4 > use {
    -webkit-animation: move-forever4 4s linear infinite;
            animation: move-forever4 4s linear infinite;
}

.parallax4 > use :nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
    100% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
    100% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
    100% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
    100% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
    100% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
    100% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever4 {
    0% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
    100% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
}

@keyframes move-forever4 {
    0% {
        -webkit-transform: translate(-90px, 0%);
                transform: translate(-90px, 0%);
    }
    100% {
        -webkit-transform: translate(85px, 0%);
                transform: translate(85px, 0%);
    }
}


.avatarSection {
    place-self: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;

}




.header-notification-icon {
    margin-right: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.tutorial-text {
    text-align: justify;
}

.header-notification-popover .MuiPopover-paper {
    max-width: 500px;
}

.notif-loader {
    width: 500px;
    height: 150px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.notif-loader-text {
    margin-left: 10px;
}

.notif-single-action {
    padding: 15px !important;
    display: -webkit-flex !important;
    display: flex !important;
}

.notif-image {
    margin-right: 10px;
}

.notif-image .MuiAvatar-root {
    width: 45px;
    height: 45px;
}

.notif-body {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.yb-search-box .MuiInputAdornment-root {
    margin-top: 0 !important;
}

.yb-search-box input {
    padding: 10px 12px 10px;
    border-radius: 100px;
}

.yb-search-box .MuiFilledInput-adornedStart {
    border-radius: 110px;
}

.yb-search-box .MuiFilledInput-underline::before {
    border-bottom: none !important;
}

.yb-search-box .MuiFilledInput-underline::before:hover {
    border-bottom: none !important;
}

.yb-search-box .MuiFilledInput-underline::after {
    width: 90%;
    margin: auto;
}

.yb-search-box .MuiFormControl-root {
    background: #fcfcfc;
    border-radius: 110px;
}

.yb-search-box .Mui-focused {
    background: #fcfcfc !important;
}

.yb-search-box .MuiFilledInput-root,
.yb-search-box .MuiFilledInput-root {
    background: #efefeff2;
}

.yb-search-box .MuiFilledInput-root:hover,
.yb-search-box .MuiFilledInput-root:active,
.yb-search-box .MuiFilledInput-root:focus {
    background: #fcfcfc;
}

.yb-search-box .MuiInputBase-input:focus {
    background: #fcfcfc !important;
}

.yb-college-section {
    min-height: 48vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.yb-clg-name {
    font-size: 20px;
    color: white;
    padding-bottom: 15px;
    text-align: center;
}

.yb-batch-name {
    font-size: 37px;
    color: white;
    font-weight: 700;
    padding-bottom: 23px;
}

.yb-header-mobile-logo,
.yb-header-mobile {
    display: none !important;
}

.yb-dept-chip {
    border: 1px solid #96969696 !important;
}

.yb-dept-chip:hover {
    background: #582bee57 !important;
    border: 1px solid #582bee !important;
    color: black !important;
}

.dept-chips-left-scroll {
    position: absolute;
    left: 0;
    width: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
    z-index: 10000;
}

.dept-chips-right-scroll {
    position: absolute;
    right: 0;
    width: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
}

.yb-dept-chip {
    margin: 0 6px !important;
}

.dept-chips-scroll-container {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    max-width: 100vw;
    scroll-behavior: smooth;
}

.dept-chips-left-arrow, .dept-chips-right-arrow {
    color: black !important;
    background: #582bee3b;
}

.dept-chips-wrapper {
    display: -webkit-flex;
    display: flex;
    padding: 6px 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 12px;
}

.loading-more {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 12vh 0;
    color: #777;
}

.loading-more-text{
    font-size: 15px;
}

@media only screen and (max-width: 768px) {
    .header-notification-icon {
        margin-right: 0;
    }

    .yb-header {
        padding: 0 1rem !important;
    }

    .yb-header-mobile-logo {
        display: block !important;
        margin-top: 0.3rem !important;
        margin-bottom: 0 !important;
        margin-left: 55px !important;
    }

    .notif-loader {
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .yb-header-mobile {
        display: block !important;
    }

    .dept-chips-scroll-container {
        padding: 10px 0;
        overflow: auto !important;
    }

    .dept-chips-wrapper {
        padding: 0 !important;
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
    }

    .dept-chips-wrapper::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    .yb-cards {
        padding: 0 !important;
    }

    .yb-card {
        margin: 5px !important;
    }

    .mobile-nav-close {
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .loading-more-text{
        padding: 25px;
        text-align: center;
    }
}

.yb-cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0 2.75rem;
    -webkit-justify-content: center;
            justify-content: center;
}

.yb-card {
    -webkit-flex-basis: 21%;
            flex-basis: 21%;
    margin: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.yb-card-content-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 250px;
    background: #e2e2e2;
    border-radius: 13px;
}

.yb-card-photo {
    position: relative;
}

.yb-card-quoname-container {
    position: absolute;
    bottom: 11px;
    padding: 0 10px;
    color: white;
}

.yb-card-user-name {
    color: white;
    font-size: 22px;
    font-weight: 500;
}

.yb-card-photo img {
    width: 250px;
    height: 350px;
    object-fit: cover;
    border-radius: 13px;
}

.yb-card-bg {
    position: absolute;
    width: 100%;
    border-radius: 13px;
    height: 50%;
    top: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.95) 100%);
    transition: all 0.2s ease;
}

.yb-card-bg-hovered{
    height: 60%;
    top: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.95) 100%);
}

.yb-card-share-star-container {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    top: 15px;
    right: 15px;
}

.yb-card-icon{
    fill: white !important;
    height: 30px !important;
    width: 30px !important;
}

.yb-card-icons-bg{
    position: absolute;
    width: 100%;
    border-radius: 13px;
    height: 0;
    top: 0;
    transition: all 0.3s ease;
}

.yb-card-icons-bg-hovered{
    height: 80%;
    background: linear-gradient(45deg, rgba(255,255,255,0) 68%, rgba(0,0,0,0.75) 100%);
}

.icons-hidden{
    opacity: 0;
    transition: all 0.3s ease;
}

.icons-visible{
    opacity: 1;
}
.share-link-box .MuiFormControl-root {
    width: 100%;
}

.share-link-box input {
    padding: 15px;
}

.share-link-box .MuiInputBase-root {
    border-radius: 10px;
}

.share-link-box .MuiFilledInput-underline::before {
    border-bottom: none !important;
}

.share-link-box .MuiFilledInput-underline::before:hover {
    border-bottom: none !important;
}

.share-link-box .MuiFilledInput-underline::after {
    width: 20%;
    margin: auto;
}

.share-link-dialog .MuiDialogTitle-root {
    padding: 7px 24px;
}
.searchContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'searchHeading' 'searchContent';
}

.searchHeading {
  font-size: 27px;
  place-self: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1rem;
  color: #000;
  text-transform: uppercase;
  float: left;
}

@media (max-width: 500px) {
  .searchHeading {
    place-self: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2rem;
    color: #000;
    text-transform: uppercase;
    float: left;
  }

  .searchFilterArea {
    width: 100%;
    padding-right: 2%;
    padding-top: 1%;
    border-bottom: 1px solid #f1f1f1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50% 50%;
    grid-template-areas: 'searchSelectBox', 'searchBoxInResult';
  }

  .searchSelectBox {
    justify-self: center;
    width: 300px;
    margin-bottom: 1rem;
  }

  .searchBoxInResult {
    justify-self: center;
    width: 300px;
    margin-top: 1rem;
  }
}

@media (min-width: 501px) {
  .searchFilterArea {
    width: 100%;
    padding-right: 2%;
    padding-top: 1%;
    border-bottom: 1px solid #f1f1f1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: 'searchSelectBox', 'searchBoxInResult';
  }

  .searchSelectBox {
    justify-self: left;
  }

  .searchBoxInResult {
    justify-self: right;
  }
}

.noResultsFound {
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin: 2rem auto;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.searchContent {
  margin: 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  grid-template-areas: 'searchFilterArea' 'searchCardArea' 'searchPaginationArea';
  float: left;
  position: relative;
}

.searchPaginationArea {
  justify-self: center;
}

#searchBackDrop {
  background: #f1f1f1;
  height: 100%;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #3caea3 !important;
  color: #fff !important;
}

@media (min-width: 901px) {
  #searchBackDrop {
    height: 100%;
    margin-left: 82px;
  }
}

@media (max-width: 900px) {
  #searchBackDrop {
    height: 100%;
    margin-top: 60px;
  }
}

.backdropIn {
  z-index: 2000 !important;
  color: #fff;
}

@media (min-width: 901px) {
    .howToButtonHolder {
        margin-left: 82px;
        height: 100%;
    }
}

@media (max-width: 900px) {
    .howToButtonHolder {
        margin-top: 60px;
        height: calc(100% - 60px)
    }
}

.howToButtonHolder {
    background: rgba(60, 174, 163, 0.9); /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9));
    width: 100%;
    display: grid;
    place-items: center;
}

.howToButton {
    padding: 2rem;
    border-radius: 10px;
    border: black solid 3px !important;
    background-color: #fff !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #3caea3 !important;
}

.howToButton:hover {
    border: black solid 3px !important;
    background-color: #3caea3 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #fff !important;
}
.collegeSelectHero {
    -webkit-appearance: none;
            appearance: none;
    background: #a0aec012;
    border-color: #00000047;
    border-width: 1px !important;
}

.collegeSelectHero:hover {
    border-color: #a0aec0 !important;
}

.partnerColleges {
    display: -webkit-flex;
    display: flex;
    margin-top: 15px;
}

.partnerCollege {
    color: #a0aec0;
    border: 1px solid #a0aec0;
    padding: 7px;
    margin: 0 5px;
    border-radius: 15px;
    transition: all 0.2s ease-in;
}

.partnerCollege:hover {
    background: #e1e7ea;
    color: #8e959f;
    cursor: default;
}

.gg-your-college {
    margin-top: 15px;
    color: #582BEE;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    transition: all 0.1s ease;
    font-size: 0.85rem;
}

.gg-your-college:hover {
    border-bottom: 1px solid;
}

.mobile-nav-close {
    position: absolute;
    top: 15px;
    left: 15px;
}

.headerSignUpLink {
    background: #582BEE !important;
    color: white !important;
    transition: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
    margin-top: -7px !important;
}

.headerSignUpLink:hover {
    background: #2b00b7 !important;
}

.hero-decorator-blob-1 {
    height: 27rem;
    width: 38rem;
    --transform-translate-x: -15.67% !important;
    --transform-translate-y: -29% !important;
}

.hero-decorator-blob-2 {
    height: 27rem;
    width: 38rem;
    --transform-translate-x: 26.33% !important;
    --transform-translate-y: 24% !important;
}

@media screen and (max-width: 900px) {
    .partnerColleges {
        -webkit-justify-content: center;
                justify-content: center;
    }

    .coming-soon-mobile {
        text-align: center !important;
    }

    .gg-your-college {
        margin: 15px auto 0 auto;
    }

    .hero-decorator-blob-1 {
        --transform-translate-x: -40.67% !important;
        --transform-translate-y: -63% !important;
    }

    .hero-decorator-blob-2 {
        display: none;
    }

    .features-section-contaienr {
        margin-left: -17px !important;
    }
}

@media screen and (min-width: 1400px) {
    .hero-decorator-blob-2 {
        display: none;
    }
}
.heading-college {
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 2.25rem;
  /* font-size: 1rem; */
  color: #000000;
  line-height: 1.25;
  padding-top: 20px;
}
.heading-dept {
  font-family: 'Inter', sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.1em;
  padding: 20px 0;
  color: #000;
  padding-bottom: 80px;
  /* font-size: 0.5rem; */
}

/* .makeStyles-gridList-2 {
  margin: auto !important;
} */
.MuiGridListTile-tile img {
  height: 100%;
  width: 100px;
  object-fit: cover;
  z-index: 100 !important;
}
.MuiGridList-root {
  margin: auto !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 100 !important;
}
.MuiGridListTile-root {
  width: auto !important;
  padding: 0 !important;
}
.refContainer {
  width: 100%;
  margin: auto;
  text-align: center;
}
.deptContainer {
  background: rgba(108, 108, 108, 0.05);
  border: 6px solid rgba(108, 108, 108, 0.4);
  border-radius: 16px;

  box-sizing: border-box;
  display: inline-block;
  margin: 4vh 10vw 10vh 10vw;

  height: auto;
  padding: 11vmin;
  position: relative;
  text-align: center;
}
/* .deptContainer:before {
  border-radius: 3px;
  bottom: -2.5vmin;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.123) inset;
  content: '';
  left: -2.5vmin;
  position: absolute;
  right: -2.5vmin;
  top: -2.5vmin;
} */

.buttonContainer {
  text-align: center;
  margin: 30px;
}
.buttonContainer button {
  border-radius: 34px;
  text-transform: none;
  font-size: 20px;
  margin-right: 20px;
}
.loadingSchool {
  font-size: 75px !important;
}
.MuiCircularProgress-colorPrimary {
  color: #3caea3 !important;
}
#loading {
  font-size: 40px;
  border: 1px solid rgba(0, 0, 0, 0.644);
  color: #fff;
}

.dept-college-logo {
  max-width: 240px;
  margin: auto;
  padding-bottom: 10px;
}
.content-dept-pic {
  text-align: center;
  padding-top: 50px;
}
.content-dept-pic h1 {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}
.content-dept-pic p {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 8%;
  padding-top: 16px;
  padding-bottom: 20px;
  color: #6c6c6c;
}

#save-button svg {
  color: white;
}

.dept-pic-single-image {
  width: 100px;
  height: 140px;
}
.gradGogglesLogo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 50px;
  margin-bottom: -6vmin;
}
.gradGogglesLogo h6 {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8px;
}
.dept-pic-note {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px 10px;
  color: #777;
  text-align: center;
}
#vit {
  max-width: 100px;
}
#seema_d {
  max-width: 120px;
}
@media only screen and (max-width: 1032px) {
  .deptContainer {
    margin: 4vh 5vw 10vh 5vw;
    height: auto;
    padding: 8vmin;
  }
  .gradGogglesLogo {
    margin-bottom: -4vmin;
  }
}
@media only screen and (max-width: 767px) {
  .heading-college {
    font-size: 1.875rem;
  }
  .MuiGridListTile-tile img {
    height: 100%;
    width: 30px;
    object-fit: cover;
    z-index: 100 !important;
  }
  .heading-dept {
    padding-bottom: 20px;
  }
  .makeStyles-gridList-2 {
    padding-bottom: 30px !important;
  }

  .dept-pic-single-image {
    width: 39px;
    height: 54px;
  }

  .buttonContainer button {
    margin-bottom: 15px;
  }

  .gradGogglesLogo {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 630px) {
  .heading-college {
    font-size: 1.5rem;
  }
  .dept-college-logo {
    margin-top: 8vmin;
  }
  .heading-dept {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 550px) {
  .deptContainer {
    padding: 0;
  }
  .gradGogglesLogo {
    margin-bottom: 10px;
    padding-top: 0;
  }
}

.mcw-header {
  position: relative;
}

.mcw-loading {
  height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mcw-add-btn {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 87vh;
}

.mcw-add-btn-child {
  background: #582bee;
  border-radius: 100px;
  z-index: 100;
  position: absolute;
  right: 35px;
}

.mcw-add-btn svg {
  fill: white;
}

.mcw-header-college {
  position: absolute;
  top: 32vh;
  text-align: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.mcw-header-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
}

.mcw-header-clg-name {
  font-size: 30px;
  font-weight: 800;
  color: white;
}

.mcw-header-carousal-slide {
  min-height: 48vh;
  background: gray;
}

.mcw-header .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1px;
  height: 1px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #9b9b9b;
  border-radius: 100px;
}

.mcw-header .slick-active button {
  background: white !important;
}

.mcw-header .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.mcw-header .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.mcw-header .slick-prev,
.mcw-header .slick-next {
  display: none !important;
}

.mcw-header-carousal-slide-inner {
  height: 75vh;
  background-size: cover !important;
  background-position: center !important;
  color: #ffffff8f;
  font-size: 18px;
  font-weight: 500;
  padding: 35px;
  letter-spacing: 3px;
}

.mcw-letter-content {
  padding: 32px 135px;
  font-size: 16px;
}

.mcw-letter-semibold {
  font-weight: 600;
}

.mcw-scribbles-grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 32px 120px;
}

.mcw-scribble {
  -webkit-flex: auto;
          flex: auto;
  font-size: 16px;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  margin: 15px;
  border: 1px solid #582bee;
  border-radius: 15px;
  position: relative;
  padding: 15px 15px 46px;
}

.mcw-scribble img {
  max-width: 400px;
  border-radius: 15px;
  margin: auto;
}

.mcw-scribble-author {
  color: #828282;
  font-size: 15px;
}

.mcw-scribble-caption {
  text-align: center;
}

.mcw-scribble-image-author {
  text-align: right;
}

.mcw-scribble-image-details {
  width: 75%;
  margin: auto;
}

.mcw-scribble-bottom-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: absolute;
  bottom: 15px;
  grid-gap: 15px;
  gap: 15px;
  -webkit-align-items: center;
          align-items: center;
}

.mcw-scribble-likes {
  display: -webkit-flex;
  display: flex;
  color: #828282 !important;
  font-size: 15px !important;
  -webkit-align-items: center;
          align-items: center;
}

.mcw-scribble-likes svg {
  height: 18px;
  width: 18px;
  padding-right: 3px;
  -webkit-align-items: center;
          align-items: center;
}

.mcw-dialog-backdrop {
  display: -webkit-flex;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.mcw-scribble-content {
  text-align: justify;
  white-space: pre-wrap;
}

@media screen and (max-width: 900px) {
  .mcw-letter-content {
    padding: 10px 33px;
    text-align: justify;
  }

  .mcw-scribbles-grid {
    padding: 7px;
  }

  .mcw-scribble img {
    max-width: 100%;
    padding-bottom: 10px;
  }
}

#bits-goa-lawn-pic {
  background-position: bottom !important;
}

#bits-goa-sac-pic {
  background-position: top !important;
}

.formHalf {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}
.loginSignUpHolder::before {
  content: ' ';
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background: linear-gradient(99.2deg, #582bee -1%, #d95ff2 98.15%);
  z-index: -3;
}
.joinHeading {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 4% 0;
}

.logoName {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-left: 15%;
}

.joinBody {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.joinContainer {
  height: 100vh;
}

.login-bg {
  position: absolute;
  z-index: -2;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  transition: opacity 0.5s ease;
}

.joinContainer::before {
  content: ' ';
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
}

.heading-greet {
  color: #ffffff !important;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 15%;
  padding-right: 10%;
  font-size: 3rem !important;
  /* line-height: 4.563rem !important; */
  font-family: 'Inter', sans-serif;
  -webkit-justify-content: left;
          justify-content: left;
}

.auth-college-name {
  color: white;
  padding-left: 15%;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.auth-select-college {
  border: 1px solid #aeaeae;
  padding: 5px;
}

.auth-college-logo {
  padding-left: 15%;
  max-width: 50%;
}

.formHalf .MuiTypography-h6 {
  color: white;
  padding-left: 15%;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.joinBodyInner {
  width: 70%;
  min-height: 65%;
  background-color: white;
  padding: 5% 5%;
  border-radius: 8px;
}

.joinBodyInner div {
  padding: 5px;
}

.joinBody .MuiOutlinedInput-input {
  padding: 5px 8px !important;
}

.joinBody .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.logButtonHolder {
  text-align: center;
  margin-top: 20px;
}

.logButtonHolder .MuiButton-outlined,
.next-btn button {
  padding: 5px 0;
  background-color: #582bee;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.googleButton {
  text-align: center;
}

.googleButton .MuiButton-outlined {
  padding: 5px 0;
  background-color: #ffffff;
  color: #000000;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.logButtonHolder button:hover,
.next-btn button:hover {
  border-color: #582bee;
  color: #000000;
}

#orSpan {
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 0;
}

.signUpChanger {
  margin-bottom: 20px;
  margin-top: 25px;
}

.signUpChanger a {
  color: #582bee;
}

.arrowBack {
  cursor: pointer;
}

#bday-header {
  padding-bottom: 0;
  margin-top: 20px;
}

.dobHolder div {
  padding: 0;
  margin-top: 0;
  /* border: 1px solid red; */
}

.next-btn {
  margin-top: 20px;
}

.photo-Container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.photo-ContainerLeft {
  width: 50%;
  padding: 10px !important;
}

.photo-ContainerRight {
  width: 50%;
}

.photo-ContainerRight .MuiTypography-body1 {
  font-size: 14px;
  color: #6c6c6c;
  font-family: 'Inter', sans-serif;
  padding-top: 2px;
  margin-right: -50px;
}

.quoteHolder {
  margin-top: -20px;
}

#qouteArea {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #828282;
  border-radius: 8px;
  padding: 10px;
  height: 70px !important;
  resize: none !important;
}

.loginAvatarHolder .makeStyles-formControl-16,
.loginAvatarHolder .makeStyles-formControl-26 {
  margin: 0;
}

#register-final {
  margin-top: 50px !important;
}

#fullname,
#graduateDate {
  display: -webkit-flex;
  display: flex;
}

.joinBodyInner {
  position: relative;
}

.formHalf .MuiSvgIcon-root {
  color: #828282;
}

.joinBodyInner .arrowBack {
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
}

.arrowBackMobile {
  display: none !important;
}

.arrowBack {
  display: block !important;
}

@media (max-width: 1034px) {
  .joinBodyInner {
    width: 90%;
  }
}

@media (max-width: 895px) {
  .joinBodyInner {
    width: 90%;
  }

  #dept-pic {
    margin-right: -20px;
  }
}

/* Mobile View Layout */
@media (max-width: 789px) {
  .formHalf {
    display: block;
    overflow-x: hidden;
    height: 100%;
    /* text-align: center; */
    margin: auto;
  }

  .logoName {
    display: none;
  }

  .arrowBack {
    display: none !important;
  }

  .joinHeading {
    width: 100%;
  }

  .joinHeading h6, .auth-college-logo {
    display: none;
  }

  .joinBody {
    width: 100%;
    -webkit-flex-direction: unset;
            flex-direction: unset;
    min-height: 78.5%;
  }

  .heading-greet {
    padding-left: 7%;
    padding-right: 3%;
    text-align: left;
    font-family: 'Inter', sans-serif !important;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
  }

  .arrowBack {
    display: none;
  }

  .arrowBackMobile {
    display: block !important;
    margin-bottom: 20px;
    margin-left: 7%;
  }
}

@media (max-width: 364px) {
  .joinBodyInner {
    width: 95%;
  }

  .arrowBackMobile {
    margin-left: 3%;
  }

  .heading-greet {
    padding-left: 3%;
  }
}

body{
    font-family: Inter, system-ui, sans-serif;
}
