@media (min-width: 901px) {
    .howToButtonHolder {
        margin-left: 82px;
        height: 100%;
    }
}

@media (max-width: 900px) {
    .howToButtonHolder {
        margin-top: 60px;
        height: calc(100% - 60px)
    }
}

.howToButtonHolder {
    background: rgba(60, 174, 163, 0.9); /* fallback for old browsers */
    background: -webkit-linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9));
    width: 100%;
    display: grid;
    place-items: center;
}

.howToButton {
    padding: 2rem;
    border-radius: 10px;
    border: black solid 3px !important;
    background-color: #fff !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #3caea3 !important;
}

.howToButton:hover {
    border: black solid 3px !important;
    background-color: #3caea3 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #fff !important;
}