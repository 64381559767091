.share-link-box .MuiFormControl-root {
    width: 100%;
}

.share-link-box input {
    padding: 15px;
}

.share-link-box .MuiInputBase-root {
    border-radius: 10px;
}

.share-link-box .MuiFilledInput-underline::before {
    border-bottom: none !important;
}

.share-link-box .MuiFilledInput-underline::before:hover {
    border-bottom: none !important;
}

.share-link-box .MuiFilledInput-underline::after {
    width: 20%;
    margin: auto;
}

.share-link-dialog .MuiDialogTitle-root {
    padding: 7px 24px;
}