.profileCardContainer {
    box-shadow: 2px 2px 4px 0px #000;
    width: 300px;
    height: 450px;
    background: #fff;
    border-radius: 50px;
    display: grid;
    grid-template-rows: 40% 60%;
    grid-template-columns: 100%;
    margin: 1rem auto;
    grid-template-areas: "photoContainer" "infoContainer";
}

.photoContainer {
    position: relative;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-template-areas: "avatarSection";
    background: #3caea3;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-bottom: 3%;
}

.infoContainer {
    position: relative;
    display: grid;
    grid-template-rows: 20% 55% 25%;
    grid-template-columns: 100%;
    grid-template-areas: "nameDeptSection" "quoteSection" "additionalSection";
    background: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-bottom: 10%;
}

.nameDeptSection {
    justify-self: center;

}

.nameDeptSection p {
    margin: 0;
    text-align: center;
}

.nameDeptSection .name {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 21px;
}

.nameDeptSection .dept {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 13px;
    font-style: bold;
}

.quoteSection {
    justify-self: center;
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-areas:"quoteIcon" "quote";
    grid-template-columns: 100%;
    overflow: overlay

}

.quoteSection .quoteIcon {
    justify-self: center;
    text-align: center;

}

.quoteSection p {
    margin: 0.5rem;
    text-align: justify;
}

.quoteSection .quote {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 18px;
}

.additionalSection {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "dob" "email";
}

.dob {
    justify-self: center;
    text-align: center;
}

.date {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin: 0.2rem;
    letter-spacing: 0.1rem;
}

.email {
    justify-self: center;
    text-align: center;
}

.address {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin-right: 1rem;
    margin-top: 0.2rem;
    overflow-wrap: break-word;
}

.editorial {
    display: block;
    width: 100%;
    height: 30px;
    max-height: 30px;
    margin: 0;
    z-index: 5;
    bottom: 0;
    position: absolute;
    left: 0px;
    float: left;
}

.parallax1 > use {
    animation: move-forever1 10s linear infinite;
}

.parallax1 > use :nth-child(1) {
    animation-delay: -2s;
}

.parallax2 > use {
    animation: move-forever2 8s linear infinite;
}

.parallax2 > use :nth-child(1) {
    animation-delay: -2s;
}

.parallax3 > use {
    animation: move-forever3 6s linear infinite;
}

.parallax3 > use :nth-child(1) {
    animation-delay: -2s;
}

.parallax4 > use {
    animation: move-forever4 4s linear infinite;
}

.parallax4 > use :nth-child(1) {
    animation-delay: -2s;
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever4 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}


.avatarSection {
    place-self: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;

}



