/*Landing Section Div*/
@media screen and (min-width: 901px) {
    .main-sidebar + .main-menu + #homeContent {
        margin-left: 82px;
    }
}

@media screen and (max-width: 900px) {
    .main-sidebar + .main-menu + #homeContent {
        margin-top: 60px;
    }
}

#homeContent {
    zoom: 1;
}

#homeContent {
    display: block;
}

.landingSection {
    zoom: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

/*Left Div*/
@media screen and (min-width: 769px) {
    .landingSection .left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 31.51694%;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left, .landingSection .right {
        width: 100%;
    }
}

.landingSection .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 31.51694%;
}

.landingSection .left {
    background: #fff;
    color: #042f66;
}

.landingSection .left, .landingSection .right {
    float: left;
}

@media screen and (min-width: 769px) {
    .landingSection .left .icon {
        margin: 50px auto 0;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .icon {
        margin: 34px auto;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .icon {
        margin: 34px auto 0 !important;
    }
}


@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .title {
        margin: auto !important;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .icon {
        width: 250 !important;
    }
}

.landingSection .left .icon {
    display: block;
}

svg:not(:root) {
    overflow: hidden;
}

/*Section Title*/
@media screen and (min-width: 769px) {
    .landingSection .left .title {
        margin: 0 auto;
    }
}

@media (max-width: 1439px) and (min-width: 824px) {
    .landingSection .left .title {
        font-size: 3.4vw;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .title {
        margin: 34px auto;
        padding-top: 0;
    }
}

@media (min-width: 769px) and (max-width: 823px) {
    .landingSection .left .title {
        font-size: 32.00556px;
    }
}

@media (min-width: 400px) and (max-width: 768px) {
    .landingSection .left .title {
        font-size: 50px;
    }
}

@media (min-width: 1440px) {
    .landingSection .left .title {
        font-size: 56px;
    }
}

.landingSection .left .title {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    text-align: center;
    line-height: 1.35;
    padding: 7.00935% 7.00935% 0;
}

/*Section Intro Div*/

.landingSection .left .section-intro {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .02em;
    color: #000;
}

.landingSection .left .itineraries {
    font-family: "Source", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
}

@media screen and (max-width: 1439px) and (min-width: 1081px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 1.11111vw;
    }
}

@media screen and (min-width: 1440px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 16px;
    }
}

@media screen and (max-width: 1080px) and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .left .section-intro {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        font-style: normal;
        letter-spacing: .02em;
        color: #000;
    }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .landingSection .left .section-intro {
        font-size: 16px;
    }
}

@media screen and (max-width: 424px) and (max-width: 768px) and (min-width: 320px) {
    .landingSection .left .section-intro {
        font-size: 3.76471vw;
    }
}

@media screen and (max-width: 319px) and (max-width: 768px) {
    .landingSection .left .section-intro {
        font-size: 12.00941px;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left .section-intro {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: .02em;
        padding-top: 0;
        color: #000;
    }
}


.landingSection .left .section-intro {
    color: #000;
    width: 100%;
    padding: 7.00935% 7.00935% 0;
}

.landingSection .left .section-intro p {
    font-weight: 400;
}

.cms-text > :first-child {
    margin-top: 0;
}

.cms-text p {
    line-height: 1.325em;
    margin: 1.25em 0;
    text-align: justify;
}

/*Counter Divs*/

@media screen and (min-width: 1440px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 22px;
    }
}

@media screen and (max-width: 1439px) and (min-width: 1048px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 1.52778vw;
    }
}

@media screen and (max-width: 1047px) and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-size: 15.99583px;
    }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .landingSection .left .itineraries {
        font-size: 22px;
    }
}

@media screen and (max-width: 424px) and (max-width: 768px) and (min-width: 310px) {
    .landingSection .left .itineraries {
        font-size: 5.17647vw;
    }
}

@media screen and (max-width: 309px) and (max-width: 768px) {
    .landingSection .left .itineraries {
        font-size: 15.99529px;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .left .itineraries {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        font-style: bold;
        letter-spacing: 0.02em;
        text-transform: uppercase !important;
    }
}

.landingSection .left .itineraries {
    margin-bottom: auto;
    width: 100%;
    padding: 7.00935%;
}

.landingSection .left .itineraries .itineraries-heading {
    color: #000;
}

.landingSection .left .itineraries ul {
    margin: 7.00935% 0 0;
}

ul {
    padding-left: 0;
}

.landingSection .left .itineraries li {
    padding: .5em 0;
    border-bottom: 2px solid;
}

ul li {
    list-style: none;
    margin-left: 0;
}

.landingSection .section-2 .left .itineraries li:last-child {
    border-bottom: 0;
}

/*Right Div*/
@media screen and (min-width: 769px) {
    .landingSection .right {
        width: 68.48306%;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .left, .landingSection .right {
        width: 100%;
    }
}

.landingSection .right {
    position: relative;
    background: #042f66;
}

.landingSection .right .images {
    zoom: 1;
}


.landingSection .right .images .image {
    float: left;
    visibility: hidden;
    width: 100%;
    margin-right: -100%;
}

.responsive.image {
    overflow: hidden;
}

.responsive.image .aspect-maintainer {
    height: 0;
    position: relative;
    padding-top: 73.118280%;
    width: 100%;
}


.responsive.image .aspect-maintainer > div {
    bottom: -1px;
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
}

.responsive.image.restrict-width:not(.centered) img {
    width: 100%;
}

.responsive.image.restrict-width img {
    height: auto;
    max-width: 100%;
    border: 0;
}

.landingSection .right .images:after {
    clear: both;
    content: '';
    display: table;
}

/*Nav Table*/

.landingSection .right .category-links {
    zoom: 1;
    position: relative;
}

.landingSection .right .category-links .category-item {
    float: left;
    color: #f5f4ed;
    text-align: center;
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current {
        color: #042f66;
        background: #f5f4ed;
    }
}


#numeroUno {
    width: 25%;
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .category-item.current {
        color: #042f66;
        background-color: #fff;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .category-item {
        width: 50% !important;
    }
}


.landingSection .right .category-links .hover-link {
    display: block;
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

a {
    background-color: transparent;
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title {
        padding: 12.90323% 12.90323% 0;
    }
}

@media (max-width: 960px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .landingSection .right .category-links .hover-link .title {
        padding: 17px 17px 0;
    }
}

@media (max-width: 1439px) and (min-width: 961px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 1.66768vw;
    }
}


@media (min-width: 1440px) {
    .landingSection .right .category-links .hover-link .title {
        font-size: 24px;
    }
}

.landingSection .right .category-links .hover-link .title {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    position: relative;
    display: block;
    margin: 0 0 0;
    line-height: 1.15;
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current .title::before {
        border-left: 10px solid transparent;
        border-bottom: 7px solid #f5f4ed;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::before {
        width: 7px;
        right: 50%;
    }
}

@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::before, .landingSection .right .category-links .hover-link .title::after {
        content: '';
        position: absolute;
        bottom: 100%;
        border-bottom: 7px solid transparent;
    }
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .category-item.current .title::after {
        border-right: 10px solid transparent;
        border-bottom: 7px solid #f5f4ed;
    }
}


@media screen and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .title::after {
        left: 50%;
        width: 7px;
    }
}

.landingSection .right .category-links .hover-link .title span {
    display: inline-block;
    min-height: 2.25em;
}

/*Icon Table*/

@media screen and (min-width: 1281px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 12.90323% 5%;
    }
}

.landscape {
    opacity: 0.9;
    filter: brightness(72%);
    -webkit-filter: brightness(72%);
    -moz-filter: brightness(72%);
}

@media screen and (max-width: 1280px) and (min-width: 769px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 25px 25px;
    }
}


@media screen and (max-width: 768px) {
    .landingSection .right .category-links .hover-link .icon-wrap {
        padding: 0 51px 51px;
    }
}

.landingSection .right .category-links .hover-link .icon-wrap {
    height: 120px;
}

/*@media screen and (min-width: 769px){.landingSection .right .category-links .category-item.current .icon-wrap .icon {*/
/*    filter: invert(70%) sepia(70%) saturate(444%) hue-rotate(342deg) brightness(107%) contrast(101%)!important;*/
/*}}*/


.landingSection .right .category-links .hover-link .icon-wrap .icon {
    display: block;
    margin: auto;
    height: 100%;
    width: 100%;
    fill: #f5f4ed;
}

/*Panelless*/
.panelless-transition {
    position: relative;
    -webkit-transition: opacity 1000ms, visibility 1000ms;
    transition: opacity 1000ms, visibility 1000ms;
    opacity: 1;
    visibility: inherit;
    z-index: 2;
}

.panelless-transition, .standard-transition {
    margin-right: -100%;
    float: left;
    width: 100%;
}

.footer {

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    text-align: center;
    background: #fff;
    color: #042f66;
    border-top: 1px solid #042f66;
    line-height: 50px;
    height: 50px;

}

#homeContent:after {
    clear: both;
    content: '';
    display: table;
}

/*Item Text*/
.landingSection .right .category-links .category-item-text {
    zoom: 1;
    position: absolute;
    bottom: 100%;
    left: 0;
    visibility: hidden;
    text-align: left;
    width: 100%;
    padding-bottom: 20px;
}

@media (max-width: 1439px) and (min-width: 721px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 1.94444vw;
    }
}

.landingSection .right .category-links .category-item-text .heading {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    width: 36.55914%;
    float: left;
    padding: 0 20px;
    margin: 0;
    color: #ffffff;
}

@media (max-width: 720px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 14px;
    }
}


@media (min-width: 1440px) {
    .landingSection .right .category-links .category-item-text .heading {
        font-size: 28px;
    }
}

.landingSection .right .category-links .category-item-text .cms-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-style: thin;
    letter-spacing: .02em;
    font-size: 13px;
    float: left;
    width: 63.44086%;
    max-width: 450px;
    padding-right: 20px;
    color: #fff;
}

.cms-text > :first-child {
    margin-top: 0;
}

.cms-text p {
    line-height: 1.325em;
    margin: 1.25em 0;
}

.cms-text > :last-child {
    margin-bottom: 0;
}

.landingSection .right .category-links .category-item-text:after {
    clear: both;
    content: '';
    display: table;
}

@media screen and (min-width: 768px) {
    .landingSection .right .category-links .category-item.current .category-item-text {
        visibility: inherit;
    }
}

.landingSection .right .category-links .category-item-text .cms-text .explore {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.02em;
    text-transform: uppercase !important;
    font-size: 15px;
    background-image: none;
    text-decoration: none;
    display: inline-block;
    color: #042f66;
    padding: .5em 1em;
    border: 2px solid;
}

.landingSection .right .images .image.default, .landingSection .right .images .image.current {
    visibility: visible;
}

/*Second Nav Item*/
#numeroDos {
    width: 25%;
}

#numeroTres {
    width: 25%;
}

#numeroCuatro {
    width: 25%;
}

.getStartedButton {
    border: black solid 3px !important;
    background-color: transparent !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #042f66 !important;
}

.getStartedButton:hover {
    border: black solid 3px !important;
    background-color: #042f66 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    color: #fff !important;
}

.playLink {
    color: #194c8e !important;
}

.googleplay {
    height: 63px;
    transition: all 0.2s ease-in;
}

.googleplay:hover {
    transform: scale(1.02);
}

.homepageButtons {
    display: flex;
    justify-content: center;
    align-items: center;
}