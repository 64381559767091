.mcw-header {
  position: relative;
}

.mcw-loading {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mcw-add-btn {
  position: sticky;
  z-index: 100;
  top: 87vh;
}

.mcw-add-btn-child {
  background: #582bee;
  border-radius: 100px;
  z-index: 100;
  position: absolute;
  right: 35px;
}

.mcw-add-btn svg {
  fill: white;
}

.mcw-header-college {
  position: absolute;
  top: 32vh;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mcw-header-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
}

.mcw-header-clg-name {
  font-size: 30px;
  font-weight: 800;
  color: white;
}

.mcw-header-carousal-slide {
  min-height: 48vh;
  background: gray;
}

.mcw-header .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1px;
  height: 1px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #9b9b9b;
  border-radius: 100px;
}

.mcw-header .slick-active button {
  background: white !important;
}

.mcw-header .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.mcw-header .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.mcw-header .slick-prev,
.mcw-header .slick-next {
  display: none !important;
}

.mcw-header-carousal-slide-inner {
  height: 75vh;
  background-size: cover !important;
  background-position: center !important;
  color: #ffffff8f;
  font-size: 18px;
  font-weight: 500;
  padding: 35px;
  letter-spacing: 3px;
}

.mcw-letter-content {
  padding: 32px 135px;
  font-size: 16px;
}

.mcw-letter-semibold {
  font-weight: 600;
}

.mcw-scribbles-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 32px 120px;
}

.mcw-scribble {
  flex: auto;
  font-size: 16px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 15px;
  border: 1px solid #582bee;
  border-radius: 15px;
  position: relative;
  padding: 15px 15px 46px;
}

.mcw-scribble img {
  max-width: 400px;
  border-radius: 15px;
  margin: auto;
}

.mcw-scribble-author {
  color: #828282;
  font-size: 15px;
}

.mcw-scribble-caption {
  text-align: center;
}

.mcw-scribble-image-author {
  text-align: right;
}

.mcw-scribble-image-details {
  width: 75%;
  margin: auto;
}

.mcw-scribble-bottom-bar {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 15px;
  gap: 15px;
  align-items: center;
}

.mcw-scribble-likes {
  display: flex;
  color: #828282 !important;
  font-size: 15px !important;
  align-items: center;
}

.mcw-scribble-likes svg {
  height: 18px;
  width: 18px;
  padding-right: 3px;
  align-items: center;
}

.mcw-dialog-backdrop {
  display: flex;
  gap: 10px;
}

.mcw-scribble-content {
  text-align: justify;
  white-space: pre-wrap;
}

@media screen and (max-width: 900px) {
  .mcw-letter-content {
    padding: 10px 33px;
    text-align: justify;
  }

  .mcw-scribbles-grid {
    padding: 7px;
  }

  .mcw-scribble img {
    max-width: 100%;
    padding-bottom: 10px;
  }
}

#bits-goa-lawn-pic {
  background-position: bottom !important;
}

#bits-goa-sac-pic {
  background-position: top !important;
}
