.current-pp-min-h{
    min-height: 85vh !important;
}

.current-pp-card{
    position: relative;
}

.current-pp-scrap-visible-loading .MuiCircularProgress-root {
    height: 21px !important;
    width: 21px !important;
}
.editProfileButtonArea {
    width: 100%;
    height: 20%;
    text-align: center;
    margin: 0.5rem;
}

.editProfileButton {
    border-radius: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: italic;
    font-weight: 500;
    background-color: #3caea3;
}

.cp-avatar-wrapper {
    padding: 20px;
}

.avatarContainerDiv {
    cursor: pointer;
    object-fit: cover;
    width: 100px;
    height: 140px;
    border-radius: 8px;
    position: relative;
    margin: auto;
    padding-top: 10px;
}

.avatarContainerDiv img {
    cursor: pointer;
    object-fit: cover;
    width: 100px;
    height: 140px;
    border-radius: 8px;
}

.cp-avatar-label{
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
}

.avatarContainerDiv .fa.fa-edit {
    position: absolute;
    top: 8px;
    right: -7px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #582bee;
    box-shadow: 0 0 8px 1px #B8B8B8;
    cursor: pointer;
}

.editProfileTextFieldsContainer {
    width: 40%;
    margin: auto;
}

.editProfileTextField {
    width: 40%;
}

.avatarHolder {
    justify-content: center;
    margin: 1rem;
    display: flex;
}

.formHolder {
    display: flex;
    justify-content: center;
}

.formSM {
    margin: 0.65rem 0;
    width: 84%;
}

.formSM svg {
    color: #979797;
}

.editSM {
    width: 100%;
}

.editEmail, .editPassword {
    margin: 0.5rem !important;

}

.rowOneHolder {
    width: 100%;
    text-align: center;
}

.rowTwoHolder {
    text-align: center;
    width: 100%;
    padding-right: 0px;
}

.selectDept {
    width: 43%;
    margin: 0.5rem !important;
}

.editSelect {

    margin: 0.5rem !important;
}

.editDatePicker.MuiFormControl-marginNormal {
    margin: 0.5rem !important;
    padding-top: 16px !important;

}

.formHolderSomeBody {
    width: 84%;
    margin: auto;
    padding-top: 10px;
}

.textAreaLabel {
    place-self: center;
    grid-column: 2/3;
    width: 100%;
    text-align: center;
}

.textAreaInside {
    grid-column: 2/3;
    width: 100%
}

.editScrapArea {
    width: 100%;
    height: 80%;
    overflow-y: auto;
    margin: 1rem;
}


#editScrapArea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#editScrapArea::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#editScrapArea::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background: rgba(60, 174, 163, 0.9); /* fallback for old browsers */
    background: -webkit-linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(270deg, rgba(4, 47, 102, 0.8), rgba(60, 174, 163, 0.9));
}

.dontShowIcon {
    display: none !important;
}

.showIcon {
    display: inline-block !important;
}

.hiddenInputEdit {
    display: none !important;
}

.MuiDialog-root {
    z-index: 1800 !important;
}

.MuiPopover-root {
    z-index: 1900 !important;
}

.changePasswordHolder, .currentPasswordHolder, .newPasswordHolder, .changePasswordButtonHolder {
    display: grid;
    place-items: center;
}

.changePasswordHolder {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.currentPasswordHolder, .newPasswordHolder, .changePasswordButtonHolder {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.loadingSchool {
    color: #3caea3;
    font-size: 75px !important;
    animation: blinker 1s linear infinite;
}

.topSMForm {
    margin-top: 10px;
}

@keyframes blinker {
    50% {
        opacity: 0
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@media screen and (max-width: 900px) {
    .formHolderSomeBody{
        width: 70%;
        padding: 25px 0;
    }
    .selectDept{
        margin-left: -20px !important;
    }
    .current-pp-mobile-container{
        border-radius: 0 !important;
        margin:0!important;
    }
    .rowTwoHolder, .rowOneHolder{
        display: flex;
        justify-content: center;
    }
    .editProfileTextFieldsContainer{
        width: 100%;
    }
    .editProfileTextField{
        width: 45%;
    }
    .formHolderSomeBody{
        width: 95%;
    }
    .formSM{
        width: 94%;
    }
    .topSMForm{
        margin-top: 0;
    }
}